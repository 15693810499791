<template>
  <div class="register-box">
    <van-field
      class="transpranent-input"
      placeholder="请输入手机号"
      type="digit"
      v-model="phoneNumber"
      maxlength="11"
      @blur="blurPhone"
      :readonly="codeContent=='获取验证码'?false:true"
    />
    <div class="code-graphic">
      <van-field
        class="transpranent-input"
        placeholder="输入验证码"
        v-model="graphicCode"
        maxlength="4"
      />
      <p class="code-red" v-show="graphicFlag">验证码输入不正确</p>
      <picture-captcha :image_code_url="image_code_url" @generate_image_code="generate_image_code"></picture-captcha>
    </div>
    <div class="get-code">
      <van-field
        class="transpranent-input"
        type="digit"
        maxlength="6"
        placeholder="输入验证码"
        v-model="code"
      />
      <div class="code-text" @click="getCode">
        {{codeContent}}{{codeContent!='获取验证码'?'s后重发':''}}
      </div>
    </div>
    <van-field
      class="transpranent-input"
      :type="showPassword?'text':'password'"
      placeholder="请输入密码"
      v-model="password"
      :right-icon="showPassword?require('@/assets/images/common_icon_play.png'):require('@/assets/images/common_icon_display.png')"
      @click-right-icon="clickRightIcon"
    />
    <div class="bottom-jump">
      <span @click="toPage('/register-login/pwdLogin')">密码登录</span>
      <span class="line"></span>
      <span @click="toPage('/register-login/codeLogin')">验证码登录</span>
    </div>
    <van-button
      :class="`${code&&phoneNumber&&password&&graphicCode?'':'login-register-btn-opacity'} login-register-btn`"
      @click="login"
    >
      {{this.$router.history.current.query.type=='forget' ? '登录' : '注册并登录'}}
    </van-button>

    <van-popup
      v-model="isReg"
      class="prompt-box"
      :closeable="true"
      @close="isReg=false"
      :round="true"
      :close-on-click-overlay="false"
    >
      <h3>您的手机号已注册，请去登录</h3>
      <van-button
        class="go-reg"
        @click="$router.push('/register-login/codeLogin')"
      >去登录</van-button>
    </van-popup>
  </div>
</template>

<script>
import PictureCaptcha from "@/components/PictureCaptcha";
import config from "@/fetch/config";
const { relApi } = config;
import { getMobileCount, sendSmsCode, setUsersReg } from "@/fetch/api/login";
import { Local } from '@/utils/local.js';
export default {
  components: {
    PictureCaptcha,
  },
  data() {
    return {
      code: null,
      phoneNumber: null,
      password: null,
      graphicCode: null,
      codeContent: "获取验证码",
      showPassword: false,
      graphicFlag: false,
      isReg: false, //已注册
      image_code_url: "" //验证码图片路径
    };
  },
  methods: {
    toPage(path) {
      this.$router.push(path);
    },
    blurPhone() {
      if (!/^1[3456789]\d{9}$/.test(this.phoneNumber) && this.phoneNumber) {
        this.$toast("手机号码有误，请重填");
        return false;
      }
    },
    clickRightIcon() {
      this.showPassword = !this.showPassword;
    },
    // 注册次数
    getRegCount(){
      getMobileCount({ mobile: this.phoneNumber }).then(res => {
        if(res.code==200){
          if(res.result.count > 0) {
            this.isReg = true;
          }else{
            this.isReg = false;
            if(this.codeContent == "获取验证码"){
              this.setSendSmsCode((res)=>{
                if(res.code == 200||res.msg == '请求成功'){
                  clearInterval(this.codeContentTimer);
                  this.codeContent = 60;
                  this.codeContentTimer = setInterval(() => {
                    this.codeContent = this.codeContent - 1;
                    if (this.codeContent < 1) {
                      clearInterval(this.codeContentTimer);
                      this.codeContent = "获取验证码";
                    }
                  }, 1000);
                }else{
                  this.$toast(res.msg);
                }
              });
            }
          }
        }
      });
    },
    setSendSmsCode(callback){
      console.log( this.image_code_url.split('/')[5],' this.image_code_url this.image_code_url');
      sendSmsCode({"mobile":this.phoneNumber,"text":this.graphicCode,"image_code_id": this.image_code_url.split('/')[5]}).then(res=>{
        console.log(res,'asdassdadssad');
        callback(res)
      })
    },
    getCode() {
      // 手机号&&手机类型
      if (!this.phoneNumber) {
        this.$toast("请输入手机号");
        return;
      }
      if (!/^1[3456789]\d{9}$/.test(this.phoneNumber)) {
        this.$toast("请输入正确手机号");
        return;
      }
      if (!this.graphicCode) {
        this.$toast("请输入图形验证码");
        return;
      }
      this.getRegCount();
    },
    // 生成uuid
    generate_uuid: function() {
      var d = new Date().getTime();
      if (window.performance && typeof window.performance.now === "function") {
        d += performance.now();
      }
      var uuid = "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(
        /[xy]/g,
        function(c) {
          var r = (d + Math.random() * 16) % 16 | 0;
          d = Math.floor(d / 16);
          return (c == "x" ? r : (r & 0x3) | 0x8).toString(16);
        }
      );
      return uuid;
    },
    generate_image_code: function() {
      this.image_code_id = this.generate_uuid();
      this.image_code_url = `${relApi}/common/imageCode/${this.image_code_id}`;
    },
    login() {
      let params = {
        username: this.phoneNumber,
        password: this.password,
        sms_code: this.code,
        allow: 'true',
        mobile: this.phoneNumber,
      }
      setUsersReg(params).then(res=>{
        console.log(res,'asdasdares');
        if(res.code == 200){
          let token = res.result.token;
          console.log(token, 'token----');
          Local.set('token', token);
          this.$router.replace("/home");
        }else{
          this.$toast(res.msg)
        }
      })
    }
  },
  created() {
    this.generate_image_code();
  },
  destroyed() {
    clearInterval(this.codeContentTimer);
  }
};
</script>

<style lang="scss" scoped>
.register-box {
  width: 84%;
  margin: 0 auto;
  padding-top: 4.45rem;
  overflow: hidden;
  .login-register-btn-opacity {
    opacity: 0.57;
  }
  .code-graphic {
    position: relative;
    .code-red {
      position: absolute;
      left: 0.4rem;
      color: red;
      line-height: 0.56rem;
    }
    .transpranent-input {
      width: 44%;
    }
    .code-img {
      position: absolute;
      right: 0;
      top: 0;
      width: 40%;
      height: 1.33rem;
      border-radius: 0.67rem;
    }
  }
  .get-code {
    position: relative;
    .code-text {
      position: absolute;
      width: 2.6rem;
      right: 0.67rem;
      top: 0.32rem;
      height: 0.72rem;
      line-height: 0.72rem;
      text-align: center;
      font-size: 0.4rem;
      color: #ffffff;
      border-left: 0.03rem solid #fff;
      padding-left: 0.48rem;
      opacity: 0.8;
    }
  }
}
</style>
