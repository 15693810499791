
<template>
  <img
    :src="image_code_url"
    class="code-img"
    @click="generate_image_code()"
  />
</template>
<script>
export default {
  props: {
    image_code_url: {
      type: String,
      default: ""
    },
  },
  methods: {
    generate_image_code(){
      this.$emit('generate_image_code')
    }
  },
  created() {}
};
</script>
<style lang="scss" scoped>
.code-img {
  width: 3.71rem;
  height: 1.33rem;
  border-radius: 0.67rem;
}
</style>
